
import "cropperjs/dist/cropper.css";
import { ContentLoader } from "vue-content-loader";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import useAlert from "@/composables/Alert";
import { getAdd, getView, enviarNovaSenha } from "@/services/UsuarioService";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useSelectedUsuarioStore } from "@/pinia/crudUsuario/useUsuarioStore"

export default defineComponent({
  name: "adicionarUsuario",

  components: {
    ContentLoader,
  },

  setup(_props, { emit }) {
    const loadingButton = ref(false)
    const formRef = ref<null | HTMLFormElement>(null);
    const route = useRoute();
    const usuarioStore = useSelectedUsuarioStore();
    const codUsuario = usuarioStore.getSelectedUsuario;
    const { showTimeAlert } = useAlert();
    const router = useRouter();
    const codNivel: any = ref([]);
    const loadingGenerateLink = ref(false)
    let loading = ref(false);

    let formUsuario: any = reactive({
      email: "",
      nome: "",
      senha: "",
      codNivel: "",
      programaAgenda: 1
    });    

    const programarQuadro = [
      {
        text: 'Não',
        value: 0
      },
      {
        text: 'Sim',
        value: 1
      }
    ]
    const validatePassword = (rule: any, formUsuario: any, callback: any) => {
      let password = formUsuario;

      if (password.length > 20) {
        return callback(new Error("limite de 20 caracteres"));
      } else if (password.length <= 0) {
        return callback(new Error("Digite uma senha válida"));
      }
      return true;
    };

    const validateEmail = (rule: any, formUsuario: any, callback: any) => {
      let usuario = formUsuario.substring(0, formUsuario.indexOf("@"));
      let dominio = formUsuario.substring(
        formUsuario.indexOf("@") + 1,
        formUsuario.length
      );     
      if (
        usuario.length >= 1 &&
        dominio.length >= 3 &&
        usuario.search("@") == -1 &&
        dominio.search("@") == -1 &&
        usuario.search(" ") == -1 &&
        dominio.search(" ") == -1 &&
        dominio.search(".") != -1 &&
        dominio.indexOf(".") >= 1 &&
        dominio.lastIndexOf(".") < dominio.length - 1
      ) {
        return true;
      } else {
        return callback(new Error("Favor inserir email válido"));
      }
    };

    const store = useStore();
    const distribuidor = store.getters.layoutConfig("distribuidor.distribuidor");

    const dadosUsuarioespecifico = ref({});
    const buscarcodNivelusuario = [
      {
        text: "Administrador",
        value: -1,
      },
      {
        text: distribuidor == "stellantis" ? "Gestor de checklist" : "Gerência de Pós-venda",
        value: 1,
      },
      {
        text: "Consultor",
        value: 2,
      },
      {
        text: "Orçamentista",
        value: 3,
      },
      {
        text: "Controlador de quadro",
        value: 4,
      },
      {
        text: "Visualizador de quadro",
        value: 5,
      },
    ];

    const rules = ref({
      nome: [
        {
          required: true,
          message: "Digite um nome válido",
          trigger: "change",
        },
      ],
      email: [
        {
          required: true,
          validator: validateEmail,
          trigger: "change",
        },
      ],
      codNivel: [
        {
          required: true,
          message: "Selecione um nível válido",
          trigger: "change",
        },
      ],
      senha: [
        {
          required: true,
          validator: validatePassword,
          trigger: "change",
        },
      ],
      programaAgenda: [
        {
          required: true,
          message: "Selecione uma opção",
          trigger: "change",
        }
      ]
    });

    onMounted(async () => {
      if (codUsuario) {
        getUsuario(codUsuario);
      }
    });

    async function getUsuario(codUsuario) {
      loading.value = true;
      let response = await getView(codUsuario);
      formUsuario.email = response.email;
      formUsuario.senha = response.senha;
      formUsuario.nome = response.nome;
      formUsuario.codNivel = response.codNivel;
      formUsuario.codUsuario = response.codUsuario;
      formUsuario.programaAgenda = response.programaAgenda;      
      loading.value = false;
    }

    const submit = () => {
      if (!formRef.value) return;
      formRef.value.validate((valid) => {
        if (valid) {
          const postData = {
            codUsuario: formUsuario.codUsuario,
            email: formUsuario.email,
            nome: formUsuario.nome,
            codNivel: formUsuario.codNivel,
            programaAgenda: formUsuario.programaAgenda
          };

          if(!codUsuario){
            Swal.fire({
              title: "E-mail para Definir senha de acesso?",
              html: `Um e-mail será enviado para <strong>${formUsuario.email}</strong>, o endereço cadastrado, contendo as informações necessárias para criar sua senha e realizar o primeiro acesso.`,
              confirmButtonText: "Confirmar",
            }).then((result) => {
              if (result.isConfirmed) {
                updateOrCreated(postData)                
              } else if (result.isDenied) {
              }
            });
          }else{
            updateOrCreated(postData)         
          }
        }
      });
    };
  
    async function updateOrCreated(postData) {
      loadingButton.value = true
     
      await getAdd(postData).then((response) => {        
        
        if(!response.error){
          showTimeAlert(response.message);
          goTo('/cadastros/usuario/list')  
          emit("addTask", postData); 
        }else{
          showTimeAlert(response.message,'error');
        }       
      }).catch(e => {
        showTimeAlert(e.data?.message ?? 'Não foi possível completar a solicitação', "error");
      }).finally(() => {
        loadingButton.value = false;        
      });
    }

    async function generateLinkNewPassword(){

      if(codUsuario){
        Swal.fire({
          title: "Desejar continuar?",
          text: "Ao gerar uma nova senha será enviado para o email cadatsrado um link de acesso para redefinição de senha!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Sim, enviar link!"
        }).then( async (result) => {
          if (result.isConfirmed) {
            const payload = {
              codUsuario: Number(codUsuario)
            }

            loadingGenerateLink.value = true

            await enviarNovaSenha(payload).then((response) => {
              showTimeAlert(response.message);
            }).catch((e) => {
              showTimeAlert(e.data?.message ?? 'Não foi possível completar a solicitação', 'error')
            });

            loadingGenerateLink.value = false
          }
        });
      }      
    }

    function goTo(url) {
      router.push(url);
    }

    return {
      goTo,
      loading,
      formUsuario,
      rules,
      formRef,
      submit,
      codUsuario,
      buscarcodNivelusuario,
      codNivel,
      dadosUsuarioespecifico,
      validateEmail,
      loadingButton,
      programarQuadro,
      distribuidor,
      generateLinkNewPassword,
      loadingGenerateLink
    };
  },
});
